import "./App.css";
import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import ConfigItems from "./pages/adminPanel/configItems/ConfigItems";
import PrivateRoute from "./utils/PrivateRoute";
import PageNotFound from "./components/layouts/PageNotFound";
//context api

import PortalState from "./context/portalContext/PortalState";
import UserAuthState from "./context/userAuthContext/UserAuthState";
import IncidentState from "./context/incidentContext/IncidentState";
import ProblemState from "./context/ProblemContext/ProblemState";
// import ProblemState from './context/ProblemContext/ProblemState';
// import ChangeState from './context/changeContext/ChangeState';
import RequestState from "./context/requestContext/RequestState";
import HyperlinkState from "./context/hyperLinkContext/HyperlinkState";

// user management
import UserInfoForm from "./pages/userAuth/UserInfoForm";
import UserAlert from "./components/layouts/UserAlert";
import UserInfoProfileUpdate from "./pages/userAuth/UserInfoProfileUpdate";
import UserAuthentication from "./pages/userAuth/UserAuthentication";

// incident management

import AllIncidents from "./pages/incidents/AllIncidentNew";
// import IncUpdateForm from "./pages/incidents/IncUpdateForm";
import IncUpdateForm from "./pages/incidents/UpdateIncident";
// import CreateIncForm from "./pages/incidents/CreateIncForm";
import CreateIncForm from "./pages/incidents/CreateIncident";
// import Resolved from "./pages/incidents/Resolved";
import Resolved from "./pages/incidents/ResolvedNew";
// import Open from "./pages/incidents/Open";
import Open from "./pages/incidents/OpenNew";
// import OpenUnAssigned from "./pages/incidents/OpenUnAssigned";
import OpenUnAssigned from "./pages/incidents/OpenUnAssignedNew";
import IncOverview from "./pages/incidents/IncOverview";
// import RaisedByMe from "./pages/incidents/RaisedByMe";
import RaisedByMe from "./pages/incidents/RaisedByMeNew";
// import AssignedToMe from "./pages/incidents/AssignedToMe";
import AssignedToMe from "./pages/incidents/AssignedToMeNew";
import IncSearch from "./pages/incidents/SearchIncident";
import UpdateProblem from "./pages/Problem/PrbUpdateForm";

// // // problem management
// // import CreateProblem from './components/pages/Problems/CreateProblem';
// // import AllProblems from './components/pages/Problems/AllProblems';
// // import PrbUpdateForm from './components/pages/Problems/PrbUpdateForm';
// // import OpenProblems from './components/pages/Problems/OpenProblems';
// // import OpenUnassignedProblems from './components/pages/Problems/OpenUnassignedProblems';
// // import ResolvedProblems from './components/pages/Problems/ResolvedProblems';
// // import ProblemsRaisedByMe from './components/pages/Problems/ProblemsRaisedByMe';
// // import ProblemsAssignedToMe from './components/pages/Problems/ProblemsAssignedToMe';
// // import ProblemOverview from './components/pages/Problems/ProblemOverview';

// // //change management
// // import CreateChange from './components/pages/Change/CreateChange';
// // import ChangeOverview from './components/pages/Change/ChangeOverview';
// // import AllChanges from './components/pages/Change/AllChanges';
// // import ChangeUpdateForm from './components/pages/Change/ChangeUpdateForm';
// // import OpenChanges from './components/pages/Change/OpenChenges';
// // import OpenUnassignedChanges from './components/pages/Change/OpenUnassignedChanges';
// // import ChangesRaisedByMe from './components/pages/Change/ChangesRaisedByMe';
// // import ChangesAssignedToMe from './components/pages/Change/ChangesAssignedToMe';

// // request management
// import Products from "./pages/Request/Products";
import Products from "./pages/Request/ProductRequest";
// import Service from "./pages/Request/Service";
import Service from "./pages/Request/ServiceRequest";
// import CreateRequest from "./pages/Request/CreateRequest";
import CreateRequest from "./pages/Request/CreateRequestNew";
// import AllRequests from "./pages/Request/AllRequests";
import AllRequests from "./pages/Request/AllRequestsNew";
// import UpdateRequest from "./pages/Request/UpdateRequest";
import UpdateRequest from "./pages/Request/UpdateRequestNew";
// import ReqRaisedByMe from "./pages/Request/ReqRaisedByMe";
import ReqRaisedByMe from "./pages/Request/ReqRaisedByMeNew";
// import ReqOpen from "./pages/Request/ReqOpen";
import ReqOpen from "./pages/Request/ReqOpenNew";
// import ReqResolved from "./pages/Request/ReqResolved";
import ReqResolved from "./pages/Request/ReqResolvedNew";
import ReqOverview from "./pages/Request/ReqOverview";
// import RequestApproval from "./pages/adminPanel/RequestApproval";
//import OtherRequests from "./pages/Request/OtherRequests";
import OtherRequest from "./pages/Request/OtherRequest";

// import FirstApproval from "./pages/Request/FirstApproval";
import FirstApproval from "./pages/Request/FirstApprovalNew";
// import SecondApproval from "./pages/Request/SecondApproval ";
import SecondApproval from "./pages/Request/SecondApprovalNew";
// import ApprovalScreen from "./pages/Request/ApprovalScreen";
import ApprovalScreen from "./pages/Request/ApprovalScreenNew";
// import RetAndRep from "./pages/Request/RetAndRep";
// import ReqSearch from "./pages/Request/ReqSearch";

// // admin panel

// import Categories from "./pages/adminPanel/Categories";
import Categories from "./pages/adminPanel/CategoriesNew";
// import SubCategories from "./pages/adminPanel/SubCategories";
import SubCategories from "./pages/adminPanel/SubCategoriesNew";
import ConfigurationItem from "./pages/adminPanel/ConfigurationItems";
// import AdminProducts from "./pages/adminPanel/Products";
import AdminProducts from "./pages/adminPanel/ProductsNew";
// import ProductCategory from "./pages/adminPanel/ProductCategory";
import ProductCategory from "./pages/adminPanel/ProductCategoryNew";
// import ProductSubCategory from "./pages/adminPanel/ProductSubCategory";
import ProductSubCategory from "./pages/adminPanel/ProductSubCategoryNew";
// import Services from "./pages/adminPanel/Services";
import Services from "./pages/adminPanel/ServicesNew";
import Departments from "./pages/adminPanel/NewDepartments";
// import Departments from "./pages/adminPanel/Departments";
import ApprovedUsers from "./pages/adminPanel/ApprovedUsersNew";
// import ApprovedUsers from "./pages/adminPanel/ApprovedUsers";
import UnapprovedUsers from "./pages/adminPanel/UnapprovedUsersNew";
// import UnapprovedUsers from "./pages/adminPanel/UnapprovedUsers";
import PasswordConfig from "./pages/adminPanel/PasswordConfig";
// import Notifiers from "./pages/adminPanel/Notifiers";
import AssetManagement from "./pages/adminPanel/AssetManagement";
// import AssetManagement from "./pages/adminPanel/AssetManagementNew";
import UnauthorizeUser from "./components/layouts/UnauthorizeUser";

// // hyperlinks

// import IncProggressBarList from "./pages/HyperLinks/IncProggressBarList";
import IncTableList from "./pages/HyperLinks/IncTableList";
// import ReqproggressBarList from "./pages/HyperLinks/ReqproggressBarList";
import FilterRequest from "./pages/Request/FilterRequest";
// import ReqtableList from "./pages/HyperLinks/ReqtableList";
import TableRequest from "./pages/Request/TableRequest";
// import PrbProggressBarList from './components/pages/HyperLinks/PrbProggressBarList';
// import PrbTableList from './components/pages/HyperLinks/PrbTableList';
// import ChngTableList from './components/pages/HyperLinks/ChngTableList ';
// import ChngProggressBarList from './components/pages/HyperLinks/ChngProggressBarList';

import AllTasks from "./pages/task-management/all-tasks";
import MyTaskPage from "./pages/task-management/my-tasks";
import AssignedTaskPage from "./pages/task-management/assigned-tasks";
import Dashboard from "./pages/incidents/Dashboard";
import WorkflowConfiguration from "./pages/adminPanel/workflowConfiguration";
import NewFlow from "./pages/adminPanel/workflowConfiguration/NewFlow";
import SLAConfiguration from "./pages/adminPanel/SLAConfiguration";
import TenantRegister from "./pages/tenant/TenantRegister";
import EmailNotifications from "./pages/adminPanel/Email-notifications";
import Configuration from "./pages/adminPanel/Configuration";
import SkipLevelManager from "./pages/adminPanel/SkipLevelManager";
import { publicPaths, tenantPublicPaths } from "./constants/other";
import FilterIncident from "./pages/incidents/FilterIncident";
import Users from "./pages/adminPanel/Users";
import HomePage from "./pages/website/pages/HomePage";
import ContactUs from "./pages/website/pages/ContactUs";
import RequestReturnReplace from "./pages/Request/RequestReturnReplace";
import FullFillmentReport from "./pages/Request/FullFillmentReport";
import MyTaskPageNew from "./pages/Task New/my-tasks-new";
import createTask from "./pages/Task New/create-task";
import CreateTask from "./pages/Task New/create-task";
import Createproblem from "./pages/Problem/CreateNewProblem";
import AllNewProblem from "./pages/Problem/AllNewProblem";
import PrbAssignedToMe from "./pages/Problem/ProblemAssignedToMe";
import AssignedTasks from "./pages/Task New/assignedTasks";
import Task from "./pages/Task New/Task";
// import SideNavbar from "./components/layouts/MainSidenavbar/SideNavbar";
// import NewProducts from "./pages/adminPanel/NewProducts";
import ProblemOverview from "./pages/Problem/ProblemDashboard";
import CreateChange from "./pages/Change New/CreateChange";
import AllChanges from "./pages/Change New/AllChanges";
import UpdateChange from "./pages/Change New/UpdateChange";
import ProblemCategory from "./pages/adminPanel/problemcategory/ProblemCategory";
import ImplementChanges from "./pages/Change New/ImplementChanges";
import OpenChanges from "./pages/Change New/OpenChanges";
import ChangesAssignedToMe from "./pages/Change New/ChangesAssignedToMe";
import ChangesRaisedByMe from "./pages/Change New/ChangesRaisedByMe";
import ChangeDashboard from "./pages/Change New/ChangeDashboard";

const prod = process.env.REACT_APP_PROD == "1";

console.log("process.env.REACT_APP_PROD", process.env.REACT_APP_PROD, prod);
const App = () => {
  const { pathname } = useLocation();
  const { replace } = useHistory();
  useEffect(() => {
    const full = window.location.hostname;
    console.log(full.split("."));
    // const isSubDomain = full.split(".")[1] === prod ? "bizitsm" : "localhost";
    const isSubDomain = full.split(".")[0] !== (prod ? "bizitsm" : "localhost");
    console.log("isSubDomain", isSubDomain);
    let token = localStorage.getItem("token");
    let data = localStorage.getItem("data");
    // console.log('data.has_profiledata.has_profiledata.has_profile',data.has_profile)

    if (isSubDomain) {
      if (token && tenantPublicPaths.includes(pathname)) {
        replace("/incident-overview");
      }
      // else if( token && !pathname.includes('not-active')){
      //   replace("/incidents-raised-by-me")
      // }
      else if (
        !token &&
        !pathname.includes("user-register") &&
        !pathname.includes("onboard")&&
        isSubDomain
      ) {
        replace("/user-login");
      }
    } else {
      if (!publicPaths.includes(pathname)) {
        window.location = prod
          ? "https://bizitsm.com"
          : "http://localhost:3000";
      }
    }
  }, []);

  return (
    <PortalState>
      <UserAuthState>
        <IncidentState>
          <ProblemState>
            {/*<ChangeState> */}
            <RequestState>
              <HyperlinkState>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/contact-us" component={ContactUs} />
                  {/* <Route exact path="/" component={LandingPage} /> */}
                  <Route path="/onboard" component={TenantRegister} />
                  <PrivateRoute path="/dashboard" component={IncOverview} />
                  {/* <PrivateRoute exact path="/" component={Dashboard} /> */}
                  <PrivateRoute
                    exact
                    path="/incident-overview"
                    component={Dashboard}
                  />
                  <PrivateRoute
                    path="/create-incident"
                    component={CreateIncForm}
                  />
                  <PrivateRoute path="/all-tasks" component={AllTasks} />{" "}
                  <PrivateRoute path="/my-tasks" component={MyTaskPageNew} />{" "}
                  <PrivateRoute path="/create-task" component={CreateTask} />{" "}
                  <PrivateRoute path="/task" component={Task} />{" "}
                  <PrivateRoute
                    path="/assigned-tasks"
                    component={AssignedTasks}
                  />
                  <PrivateRoute
                    path="/all-incidents"
                    component={AllIncidents}
                  />
                  <PrivateRoute
                    path="/update-incident/:inc_num"
                    component={IncUpdateForm}
                  />
                  <PrivateRoute
                    path="/open-unassigned-incidents"
                    component={OpenUnAssigned}
                  />
                  <PrivateRoute path="/open-incidents" component={Open} />
                  <PrivateRoute
                    path="/resolved-incidents"
                    component={Resolved}
                  />
                  <PrivateRoute
                    path="/incidents-raised-by-me"
                    component={RaisedByMe}
                  />
                  <PrivateRoute
                    path="/incidents-assigned-to-me"
                    component={AssignedToMe}
                  />
                  <PrivateRoute
                    path="/search-incidents"
                    component={IncSearch}
                  />
                  <PrivateRoute
                    path="/create-problem"
                    component={Createproblem}
                  />
                  <PrivateRoute
                    path="/problem-overview"
                    component={ProblemOverview}
                  />
                  {/* <PrivateRoute
                        path='/problem-overview'
                        component={ProblemOverview}
                      />
                      <PrivateRoute
                        path='/create-new-problem'
                        component={CreateProblem}
                      />
                      <PrivateRoute
                        path='/all-problems'
                        component={AllProblems}
                      />
                      <PrivateRoute
                        path='/update-problem/:prb_num'
                        component={PrbUpdateForm}
                      />
                      <PrivateRoute
                        path='/open-problems'
                        component={OpenProblems}
                      />
                      <PrivateRoute
                        path='/open-unassigned-problems'
                        component={OpenUnassignedProblems}
                      />
                      <PrivateRoute
                        path='/resolved-problems'
                        component={ResolvedProblems}
                      />
                      <PrivateRoute
                        path='/problems-raised-by-me'
                        component={ProblemsRaisedByMe}
                      />
                      <PrivateRoute
                        path='/problems-assigned-to-me'
                        component={ProblemsAssignedToMe}
                      />
                      */}
                  <PrivateRoute
                    path="/change-overview"
                    component={ChangeDashboard}
                  />
                  <PrivateRoute
                    path="/create-new-change"
                    component={CreateChange}
                  />
                  <PrivateRoute path="/all-changes" component={AllChanges} />
                  <PrivateRoute
                    path="/update-change/:chng_num"
                    component={UpdateChange}
                  />
                  <PrivateRoute
                    path="/implement-changes"
                    component={ImplementChanges}
                  />
                  <PrivateRoute path="/open-changes" component={OpenChanges} />
                  <PrivateRoute
                    path="/changes-assigned-to-me"
                    component={ChangesAssignedToMe}
                  />
                  <PrivateRoute
                    path="/changes-raised-by-me"
                    component={ChangesRaisedByMe}
                  />
                  {/*    <PrivateRoute
                        path='/open-unassigned-changes'
                        component={OpenUnassignedChanges}
                      />
                      */}
                  <PrivateRoute
                    path="/request-overview"
                    component={ReqOverview}
                  />
                  <PrivateRoute path="/request-products" component={Products} />
                  <PrivateRoute path="/request-services" component={Service} />
                  <PrivateRoute
                    path="/create-new-request/:type"
                    component={CreateRequest}
                  />
                  <PrivateRoute path="/all-requests" component={AllRequests} />
                  <PrivateRoute
                    path="/update-request/:req_num"
                    component={UpdateRequest}
                  />
                  <PrivateRoute
                    path="/requests-raised-by-me"
                    component={ReqRaisedByMe}
                  />
                  {/* <PrivateRoute
                  path="/requests-for-return-and-replace"
                  component={RetAndRep}
                /> */}
                  <PrivateRoute
                    path="/requests-for-return-and-replace"
                    component={RequestReturnReplace}
                  />
                  <PrivateRoute path="/open-requests" component={ReqOpen} />
                  <PrivateRoute
                    path="/closed-complete-requests"
                    component={ReqResolved}
                  />
                  <PrivateRoute
                    path="/other-requests"
                    component={OtherRequest}
                  />
                  <PrivateRoute
                    path="/first-approval-pending-requests"
                    component={FirstApproval}
                  />
                  <PrivateRoute
                    path="/second-approval-pending-requests"
                    component={SecondApproval}
                  />
                  <PrivateRoute
                    path="/requests-for-fullfillment"
                    component={FullFillmentReport}
                  />
                  <PrivateRoute
                    path="/approval-pending/:req_num"
                    component={ApprovalScreen}
                  />
                  {/* <PrivateRoute path="/search-requests" component={ReqSearch} /> */}
                  <PrivateRoute
                    path="/incident-proggress-bar/:module"
                    component={FilterIncident}
                  />
                  {/* <PrivateRoute
                  path="/incident-proggress-bar/:module"
                  component={IncProggressBarList}
                /> */}
                  <PrivateRoute
                    path="/incident-table/:module"
                    component={IncTableList}
                  />
                  {/* <PrivateRoute
                  path="/request-proggress-bar/:module/"
                  component={ReqproggressBarList}
                /> */}
                  <PrivateRoute
                    path="/request-proggress-bar/:module/"
                    component={FilterRequest}
                  />
                  {/* <PrivateRoute
                  path="/request-table/:module/"
                  component={ReqtableList}
                /> */}
                  <PrivateRoute
                    path="/request-table/:module/"
                    component={TableRequest}
                  />
                  {/* <PrivateRoute
                        path='/problem-proggress-bar/:module/'
                        component={PrbProggressBarList}
                      />
                      <PrivateRoute
                        path='/problem-table/:module/'
                        component={PrbTableList}
                      />
                      <PrivateRoute
                        path='/change-proggress-bar/:module/'
                        component={ChngProggressBarList}
                      />
                      <PrivateRoute
                        path='/change-table/:module/'
                        component={ChngTableList}
                      /> */}
                  <PrivateRoute
                    path="/view-status"
                    component={UnauthorizeUser}
                  />
                  <PrivateRoute path="/category" component={Categories} />
                  <PrivateRoute
                    path="/problem-category"
                    component={ProblemCategory}
                  />
                  <PrivateRoute
                    path="/unapproved-users"
                    component={UnapprovedUsers}
                  />
                  <PrivateRoute
                    path="/approved-users"
                    component={ApprovedUsers}
                  />
                  <PrivateRoute path="/users" component={Users} />
                  <PrivateRoute
                    path="/sub-categories/:name/:id"
                    component={SubCategories}
                  />
                  <PrivateRoute
                    path="/configuration-item"
                    component={ConfigurationItem}
                  />
                  <PrivateRoute
                    path="/request-category"
                    component={ProductCategory}
                  />
                  <PrivateRoute
                    path="/request-sub-category/:name/:id"
                    component={ProductSubCategory}
                  />
                  <PrivateRoute path="/service" component={Services} />
                  <PrivateRoute path="/departments" component={Departments} />
                  <PrivateRoute path="/product" component={AdminProducts} />
                  <PrivateRoute
                    path="/email-config"
                    component={PasswordConfig}
                  />
                  <PrivateRoute
                    path="/notifications"
                    component={EmailNotifications}
                  />
                  <PrivateRoute
                    path="/configuration"
                    component={Configuration}
                  />
                  <PrivateRoute
                    path="/request-approval"
                    component={SkipLevelManager}
                  />
                  <PrivateRoute
                    path="/asset-management"
                    component={AssetManagement}
                  />
                  <PrivateRoute
                    path="/sla-configuration"
                    component={SLAConfiguration}
                  />
                  <PrivateRoute
                    path="/workflow-configuration/:id"
                    component={NewFlow}
                  />
                  <PrivateRoute
                    path="/workflow-configuration"
                    component={WorkflowConfiguration}
                  />
                  <PrivateRoute
                    path="/items-configuration"
                    component={ConfigItems}
                  />
                  <PrivateRoute path="/user-profile" component={UserInfoForm} />
                  <PrivateRoute path="/not-acivated" component={UserAlert} />
                  <PrivateRoute
                    path="/my-profile"
                    component={UserInfoProfileUpdate}
                  />
                  {/* problem */}
                  <PrivateRoute
                    path="/create-problem"
                    component={Createproblem}
                  />
                  <PrivateRoute
                    path="/all-new-create-problem"
                    component={AllNewProblem}
                  />
                  <PrivateRoute
                    path="/update-problem/:prb_num"
                    component={UpdateProblem}
                  />
                  <PrivateRoute
                    path="/problems-assigned-to-me"
                    component={PrbAssignedToMe}
                  />
                  <Route path="/page-not-found" component={PageNotFound} />
                  <UserAuthentication />
                  <Route path="*" component={PageNotFound} />
                  <Redirect from="*" to="/page-not-found" />
                </Switch>
              </HyperlinkState>
            </RequestState>
            {/* </ChangeState>*/}
          </ProblemState>
        </IncidentState>
      </UserAuthState>
    </PortalState>
  );
};

export default App;
